<template>
    <div style="height: calc(100vh - 110px);
      overflow-y: auto;">
        <div class="container">
            <div class="handle-box">
                <div class="handle-left">
                    <el-select v-model="typeVal" placeholder="请选择搜索类型" style="padding: 0 15px;width: 30%;"
                        @change="onChangeTypeVal">
                        <el-option v-for="item in selectContent" :key="item.field" :label="item.name"
                            :value="item.field">
                        </el-option>
                    </el-select>
                    <template v-if="field == 'status'">
                        <el-select v-model="statusVal" placeholder="选择状态" style="padding: 0 15px;width: 20%;"
                            @change="onChangStatus">
                            <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </template>
                    <template v-else>
                        <el-input placeholder="请输入搜索内容" v-model="content"
                            style="width: 35%;padding-right: 10px;"></el-input>
                        <el-button type="primary" @click="handleSearch" icon="el-icon-search" :disabled="disfle">搜索
                        </el-button>
                    </template>
                </div>
                <div class="handle-right">
                    <a :href="imgUrl + 'test.xlsx'" download="模板文件.xlsx">模板下载</a>
                    <el-button @click="handleUpload" style="margin: 0 10px;">导入信息</el-button>
                    <el-upload v-show="false" class="upload-resource" action="" :http-request="clickUploadFile"
                        ref="upload" :on-success="uploadSuccess">
                    </el-upload>
                    <el-button @click="onExportInfo">导出信息</el-button>
                    <el-button type="primary" @click="addFlag = true">新增人员</el-button>
                </div>
            </div>
            <el-table :data="tableData" border class="table" ref="filterTable" v-loading="loading">
                <el-table-column prop="uid" label="用户ID" width="120" align="center"></el-table-column>
                <el-table-column prop="name" label="用户姓名" width="180"></el-table-column>
                <el-table-column label="头像(点击查看)" align="center" prop="avatarUrl" width="150">
                    <template slot-scope="scope">
                        <el-image class="table-td-thumb" preview-teleported :src="imgUrl + scope.row.avatarUrl"
                            :preview-src-list="[imgUrl + scope.row.avatarUrl]" :z-index="10">
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="phone" label="授权手机号" width="150"></el-table-column>
                <el-table-column prop="industry" label="行业" width="180" align="center">
                </el-table-column>
                <el-table-column prop="address" label="所在地区" width="200" align="center">
                </el-table-column>
                <el-table-column prop="status" label="账号状态" width="180" align="center">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status == 1">
                            <el-tag type="success" effect="dark" @click="onQhStatus(scope.row.uid, 1)">正常</el-tag>
                        </template>
                        <template v-else>
                            <el-tag type="danger" effect="dark" @click="onQhStatus(scope.row.uid, 2)">禁用</el-tag>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="loginTime" label="最后登录时间" width="180" align="center"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="220" align="center"></el-table-column>
                <el-table-column prop="updateTime" label="修改时间" width="220" align="center"></el-table-column>
                <el-table-column label="操作" width="220" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" @click="handleEditor(scope.$index, scope.row)">
                            编辑
                        </el-button>
                        <el-button type="text" style="color:#ff2000;" @click="handleDel(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="page" :page-size="size"
                    layout="total, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="添加用户信息" :visible.sync="addFlag">
            <el-form label-position="left" :rules="ruless" ref="obj" label-width="120px" :model="obj" status-icon>
                <el-form-item label="用户姓名" prop="name">
                    <el-input placeholder="请输入姓名" prefix-icon="el-icon-edit" v-model="obj.name"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="companyName">
                    <el-input placeholder="请输入公司名称" prefix-icon="el-icon-edit" v-model="obj.companyName"></el-input>
                </el-form-item>
                <el-form-item label="所属行业" prop="industry">
                    <el-input placeholder="请输入所属行业" prefix-icon="el-icon-edit" v-model="obj.industry"></el-input>
                </el-form-item>
                <el-form-item label="业务介绍" prop="introduction">
                    <el-input placeholder="请输入业务介绍" prefix-icon="el-icon-edit" v-model="obj.introduction"></el-input>
                </el-form-item>
                <el-form-item label="地 域" prop="address">
                    <el-input placeholder="请输入地域" prefix-icon="el-icon-edit" v-model="obj.address"></el-input>
                </el-form-item>
                <el-form-item label="授权手机号" prop="phone">
                    <el-input prefix-icon="el-icon-edit" v-model="obj.phone" placeholder="请输入授权手机号"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="contactWay">
                    <el-input prefix-icon="el-icon-edit" v-model="obj.contactWay" placeholder="请输入联系方式"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="subAdd('obj')">确 定</el-button>
                <el-button @click="addFlag = false">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog title="修改用户信息" :visible.sync="upg">
            <el-form label-position="left" :rules="rules" ref="upInfo" label-width="100px" :model="upInfo" status-icon>
                <el-form-item label="用户ID" prop="uid">
                    <el-input disabled prefix-icon="el-icon-edit" v-model="upInfo.uid"></el-input>
                </el-form-item>
                <el-form-item label="用户姓名" prop="name">
                    <el-input placeholder="请输入姓名" prefix-icon="el-icon-edit" v-model="upInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="公司名称" prop="companyName">
                    <el-input placeholder="请输入公司名称" prefix-icon="el-icon-edit" v-model="upInfo.companyName"></el-input>
                </el-form-item>
                <el-form-item label="所属行业" prop="industry">
                    <el-input placeholder="请输入所属行业" prefix-icon="el-icon-edit" v-model="upInfo.industry"></el-input>
                </el-form-item>
                <el-form-item label="业务介绍" prop="introduction">
                    <el-input placeholder="请输入业务介绍" prefix-icon="el-icon-edit" v-model="upInfo.introduction"></el-input>
                </el-form-item>
                <el-form-item label="地 域" prop="address">
                    <el-input placeholder="请输入地域" prefix-icon="el-icon-edit" v-model="upInfo.address"></el-input>
                </el-form-item>
                <el-form-item label="授权手机号" prop="phone">
                    <el-input prefix-icon="el-icon-edit" v-model="upInfo.phone" placeholder="请输入授权手机号"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="contactWay">
                    <el-input prefix-icon="el-icon-edit" v-model="upInfo.contactWay" placeholder="请输入联系方式"></el-input>
                </el-form-item>
                <el-form-item label="是否展示" prop="isOpen">
                    <el-radio v-model="upInfo.isOpen" :label="1">是</el-radio>
                    <el-radio v-model="upInfo.isOpen" :label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="评  价">
                    <template v-if="comments.length <= 0">
                        <el-tag type="info">暂无评价</el-tag>
                    </template>
                    <template v-else>
                        <el-tag :key="tag" v-for="tag in comments" closable :disable-transitions="false"
                            @close="handleClose(tag)">
                            {{ tag.content }}
                        </el-tag>
                    </template>

                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="subUp('upInfo')">确 定</el-button>
                <el-button @click="upg = false">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import { queryUserList, updateByUserInfo, addUserInfo, deleteByComments, exportInfos, queryByUserComments, importInfos, deleteByUserInfo, querySearchUsers, postUsersStatus } from '@/api';
export default {
    name: "UsersAdm",
    data() {
        return {
            imgUrl: '',
            loading: true,
            disfle: true,
            typeVal: '',
            statusVal: '',
            field: '',
            val: null,
            statusList: [{ name: '正常', value: 1 }, { name: '禁用', value: 2 }],
            selectContent: [
                {
                    name: '用户ID',
                    field: 'uid'
                },
                {
                    name: '授权手机',
                    field: 'phone'
                },
                {
                    name: '姓名',
                    field: 'name'
                },
                {
                    name: '状态',
                    field: 'status'
                }
            ],
            //评价
            comments: [],
            //用户表
            tableData: [],
            page: 1,
            size: 10,
            total: 0,
            upg: false,
            addFlag: false,
            upInfo: {},
            //搜索
            content: '',
            rules: {
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" }
                ],
                companyName: [
                    { required: true, message: "请输入公司名称", trigger: "blur" }
                ],
                industry: [
                    { required: true, message: "请输入所属行业", trigger: "blur" }
                ],
                introduction: [
                    { required: true, message: "请输入业务介绍", trigger: "blur" }
                ],
                address: [
                    { required: true, message: "请输入地域", trigger: "blur" }
                ],
                phone: [
                    { required: true, message: "请输入授权手机号", trigger: "blur" }
                ],
                contactWay: [
                    { required: true, message: "请输入联系方式", trigger: "blur" }
                ]
            },
            obj: {
                name: '',
                companyName: '',
                industry: '',
                introduction: '',
                address: '',
                phone: '',
                contactWay: ''
            },
            ruless: {
                name: [
                    { required: true, message: "请输入姓名", trigger: "blur" }
                ],
                companyName: [
                    { required: true, message: "请输入公司名称", trigger: "blur" }
                ],
                industry: [
                    { required: true, message: "请输入所属行业", trigger: "blur" }
                ],
                introduction: [
                    { required: true, message: "请输入业务介绍", trigger: "blur" }
                ],
                address: [
                    { required: true, message: "请输入地域", trigger: "blur" }
                ],
                phone: [
                    { required: true, message: "请输入授权手机号", trigger: "blur" }
                ],
                contactWay: [
                    { required: true, message: "请输入联系方式", trigger: "blur" }
                ]
            }
        };
    },
    watch: {
        content: {
            handler(val) {
                if (val) {
                    this.disfle = false
                } else {
                    this.disfle = true
                    this.getUserPage();
                }
            }
        }
    },
    methods: {
        //导出信息
        onExportInfo() {
            const hide =  this.$message.loading('导出中...');
            exportInfos().then((res) => {
                let blob = new Blob([res], {
                    // type: 'application/actet-stream;charset=utf-8'
                    type: 'application/vnd.ms-excel'
                }); // 构造一个blob对象来处理数据，并设置文件类型
                let a = document.createElement('a'); // 生成一个a标签
                let href = window.URL.createObjectURL(blob); //创建下载的链接
                a.href = href; //指定下载链接
                a.download = '人员信息' //指定下载文件名
                // a.setAttribute('download', 'fileName')
                document.body.appendChild(a); //把a标签加到页面中
                a.click(); //触发a标签下载
                document.body.removeChild(a); //下载完成移除元素
                window.URL.revokeObjectURL(href); //释放掉blob对象
                setTimeout(hide,500);
                this.$message.success('导出成功');
            })
        },
        //切换状态
        onQhStatus(uid, status) {
            this.$confirm(`是否确定修改账号状态为:${status == 1 ? '禁用' : '正常'}`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(async () => {
                let ss = status == 1 ? 2 : 1;
                const res = await postUsersStatus({ uid, status: ss });
                if (res.code != 0) {
                    this.$message.warning('修改失败');
                    return false;
                }
                let index = this.tableData.findIndex((item) => item.uid == uid);
                this.tableData[index].status = ss;
                this.$message.success('修改成功');
            })
        },
        // 模拟点击
        handleUpload() {
            document.querySelector(".upload-resource .el-upload").click()
        },
        // 上传文件
        async clickUploadFile(file) {
            const formData = new FormData()
            formData.append('file', file.file)
            const hide =  this.$message.loading('导入中...');
            const res = await importInfos(formData);
            setTimeout(hide,100);
            if (res.code != 0) {
                this.$message.warning(res.msg);
                return false;
            }
            this.page = 1;
            this.getUserPage();
            this.$message.success('导入成功');

        },
        // 上传成功后，清空组件自带的文件列表
        uploadSuccess() {
            this.$refs.upload.clearFiles()
        },
        //选择类型
        onChangeTypeVal(field) {
            if (field != 'status') {
                this.statusVal = '';
            }
            this.field = field;
            this.content = '';
        },
        //选择不同状态筛选
        onChangStatus(status) {
            this.content = status;
            this.page = 1;
            this.getSearch();
        },
        async getSearch() {
            try {
                this.loading = true;
                let field = this.field;
                let content = this.content;
                let pageNum = this.page;
                let pageSize = this.size;
                console.log(content);

                let res = await querySearchUsers({ pageNum, pageSize, field, content });
                this.tableData = res.list;
                this.total = res.total;
                setTimeout(() => {
                    this.loading = false;
                }, 500)
            } catch (error) {
                this.$message.error('服务器错误');
            }
        },
        //删除评价
        handleClose(tag) {
            this.$confirm("是否确定删除该评价?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                let index = this.comments.findIndex((item) => item.id == tag.id);
                let obj = this.comments[index];
                deleteByComments({ id: obj.id, uid: obj.uid }).then((res) => {
                    let { code } = res;
                    if (code != 0) {
                        this.$mm({
                            showClose: true,
                            message: '删除失败',
                            type: "error",
                        });
                        return false
                    }
                    this.comments.splice(index, 1);
                    this.$mm({
                        showClose: true,
                        message: '删除成功',
                        type: "error",
                    });
                })
            })
        },
        //编辑
        handleEditor(index, row) {
            this.upg = true;
            let obj = JSON.stringify(row);
            obj = JSON.parse(obj);
            this.getComments(obj.uid);
            this.upInfo = obj;
        },
        //添加操作
        subAdd(formName) {
            this.$refs[formName].validate((volid) => {
                if (volid) {
                    this.$confirm("是否确定新增该人员信息?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        addUserInfo(this.obj).then((res) => {
                            let { code, msg } = res;
                            if (code != 0) {
                                this.$message.warning(msg);
                                return false
                            }
                            this.addFlag = false;
                            this.obj = {
                                name: '',
                                companyName: '',
                                industry: '',
                                introduction: '',
                                address: '',
                                phone: '',
                                contactWay: ''
                            };
                            this.getUserPage();
                            this.$message.success(msg);
                        })
                    })
                } else {
                    return false;
                }
            })
        },
        handleDel(index, row) {
            this.$confirm("此操作将永久删除该人员, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                deleteByUserInfo(row.uid).then((res) => {
                    if (res.code != 0) {
                        this.$message.error(res.msg);
                        return false;
                    }
                    this.$message.success(res.msg);
                    this.loading = true
                    this.tableData = this.tableData.filter((todo) => {
                        return todo.uid != row.uid;
                    })
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            });
        },
        //搜索
        handleSearch() {
            if (this.field == '') {
                this.$message.warning('请选择类型');
                return false;
            }
            this.page = 1;
            this.getSearch();
        },
        //向后端传入修改的数据
        subUp(formName) {
            this.$refs[formName].validate((volid) => {
                if (volid) {
                    this.$confirm("是否确定修改该人员信息?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }).then(() => {
                        updateByUserInfo(this.upInfo).then((res) => {
                            let { code, msg } = res;
                            if (code != 0) {
                                this.$message.warning(msg);
                                return false
                            }
                            this.upg = false;
                            this.upInfo = {};
                            this.getUserPage();
                            this.$message.success(msg);
                        })
                    })

                } else {
                    return false;
                }
            })
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getUserPage();
        },
        //获取指定用户的评价列表
        async getComments(uid) {
            try {
                let res = await queryByUserComments({ uid });
                this.comments = res.data;
            } catch (error) {
                this.$message.error('服务器错误');
            }
        },
        async getUserPage() {
            try {
                this.loading = true;
                let pageNum = this.page;
                let pageSize = this.size;
                let res = await queryUserList({ pageNum, pageSize });
                this.tableData = res.list;
                this.total = res.total;
                setTimeout(() => {
                    this.loading = false;
                }, 500)
            } catch (error) {
                this.$message.error('服务器错误');
            }
        }
    },
    created() {
        this.imgUrl = this.$base.url;
        this.getUserPage();
    },
};
</script>

<style scoped>
.pp {
    margin-top: 10px;
    float: right;
    color: #909399;
}

.handle-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.handle-left {
    width: 55%;
}



.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
}

.table {
    width: 100%;
    font-size: 14px;
}

.mr10 {
    margin-right: 10px;
}

.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    border-radius: 6px;
    border: 1px #cdcdcd dashed;
    height: 150px;
    cursor: pointer;
}

.avatar-uploader-icon {
    font-size: 26px;

}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 6px;
}
</style>