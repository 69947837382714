<template>
        <router-view > 
        </router-view>
</template>

<script>
import'animate.css';
export default {
    name:'Right',
    
}
</script>

<style>

</style>