<template>
  <div class="dsh">
    
    <div class="let">
      <p>欢迎使用后台管理系统</p>
      <img :src="imageUrl" alt="">
    </div>
  </div>
</template>

<script>
import imageUrl from "@/assets/img/homee.jpg";
export default {
  name: "Dashboard",
  data() {
    return {
      imageUrl,
      list: []
    };
  },
  created() {
  },
};
</script>

<style >
.dsh {
  display: flex;
  justify-content: space-between;
  height: 700px;
}

.let {
  background: #fff;
  width: 65%;
  height: 100%;
  padding: 20px;

}

.let p {
  display: flex;
  align-items: center;
  height: 5%;
  font-weight: bold;
}

.let img {
  width: 100%;
  height: 95%;

}
</style>