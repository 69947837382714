const base = {
    get() {
        return {
            url : "https://api.hzxd888.xyz/ipaImg/",
            file:'https://api.hzxd888.xyz/utils/upload'
        };

        

            // url : "http://127.0.0.1:9001/ipaImg/",
            // file:'http://127.0.0.1:9001/utils/upload'
            
    }
}
export default base
