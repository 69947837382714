import request from "@/utils/request";

//登录
export function loginTenant(data) {
  return request({
    url: "/login",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data,
  });
}

//导入信息
export function importInfos(data){
  return request({
    url: "/users/import",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
} 

/**
 * 用户管理列表API
 */

//获取所有用户信息
export function queryUserList(params) {
  return request({
    url: "/users",
    method: "get",
    params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//修改指定用户信息
export function updateByUserInfo(data) {
  return request({
    url: "/users",
    method: "put",
    data,
  });
}

//添加用户信息
export function addUserInfo(data) {
  return request({
    url: "/users",
    method: "post",
    data,
  });
}

//删除指定用户信息
export function deleteByUserInfo(uid) {
  return request({
    url: `/users/${uid}`,
    method: "delete",
  });
}

//搜索用户信息
export function querySearchUsers(params) {
  return request({
    url: "/users/search",
    method: "get",
    params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//用户账号状态
export function postUsersStatus(data) {
  return request({
    url: "/users/status",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}


//导出信息
export function exportInfos(){
  return request({
    url: "/users/export",
    method: "get",
    responseType: 'blob'
  }); 
}

/**
 * 评论管理列表API
 */

//获取评论列表
export function queryComments(params) {
  return request({
    url: "/comments",
    method: "get",
    params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//评论信息搜索
export function querySearchComments(params) {
  return request({
    url: "/comments/search",
    method: "get",
    params,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
}

//评论信息审核
export function postAuditComments(data) {
  return request({
    url: "/comments/audit",
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      }
  });
}



//删除指定评价
export function deleteByComments(params) { 
  return request({
    url: '/comments',
    method: "delete",
    params
  });
 }


 //获取指定用户的评价列表
export function queryByUserComments(params) { 
  return request({
    url: '/comments/user',
    method: "get",
    params
  });
 }