<template>
  <div class="header">
    <div class="collapse-btn" @click="collapseChage">
      <el-icon class="el-icon-s-unfold" v-show="tt"></el-icon>
      <el-icon class="el-icon-s-fold" v-show="!tt"></el-icon>
      <div class="logo">
        <el-image style="width: 50px; height: 50px" :src="logo"></el-image>
        <span>后台管理系统</span>
      </div>
    </div>


    <div class="header-right">
      <div class="header-user-con">
        <!-- 用户名下拉菜单 -->
        <el-dropdown class="user-name" trigger="click" @command="back" size="medium">
          <span class="el-dropdown-link">
           管理员
            <el-icon class="el-icon-arrow-down el-icon--right">
              <arrow-down />
            </el-icon>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
import { removeToken, getToken } from '@/utils/auth';
export default {
  name: "Header",
  data() {
    return {
      tt: false,
      logo: require("@/assets/img/sq.png")
    };
  },
  methods: {
    collapseChage() {
      this.tt = !this.tt;
      this.$bus.$emit("tt", this.tt);
    },
    back(comm) {
      if (comm == 'loginOut') {
        this.$confirm("是否确定退出登陆?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //删除token
            removeToken();
            this.$router.push({
              path: "/login",
            }).catch(() => { })
            sessionStorage.setItem("n", "1");
            this.$message.success('退出成功');
          })
          .catch(() => { });
      }

    }
  },
  created() {
    this.$bus.$emit("tt", this.tt);

  },
};
</script>
<style scoped>
.collapse-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  float: left;
  padding: 0 21px;
  cursor: pointer;
}

.header .logo {
  display: flex;
  align-items: center;
  padding: 0 30px;
  float: left;

}

.header .logo span {
  padding-left: 5px;
  color: #000;
  font-weight: bold;
}

.header-right {
  float: right;
  padding-right: 50px;
}

.header-user-con {
  display: flex;
  height: 70px;
  align-items: center;
}

.btn-fullscreen {
  transform: rotate(45deg);
  margin-right: 5px;
  font-size: 24px;
}

.btn-bell,
.btn-fullscreen {
  position: relative;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.btn-bell-badge {
  position: absolute;
  right: 4px;
  top: 0px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: #f56c6c;
}

.user-name {
  margin-left: 10px;
}

.user-avator {
  margin-left: 20px;
}

.el-dropdown-link {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.el-dropdown-menu__item {
  text-align: center;
}
</style>
