<template>
  <div class="sidebar">
    <el-menu :default-active="n" class="sidebar-el-menu" background-color="#F5F5F5" text-color="#000"
      active-text-color="#20a0ff"  @select="handleSelect" :collapse="isCollapse">
      <el-menu-item index="1">
        <i class="el-icon-menu"></i>
        <span>系统首页</span>
      </el-menu-item>
      <el-menu-item index="2">
        <i class="el-icon-user-solid"></i>
        <span>用户管理</span>
      </el-menu-item>
      <el-menu-item index="3">
        <i class="el-icon-s-promotion"></i>
        <span>评论管理</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      n: sessionStorage.getItem("n") || '1',
      isCollapse: false,
      qx: 2
    };
  },
  methods: {
    handleSelect(key) {
      sessionStorage.setItem("n", key);
      if (key == "1") {
        this.$router.push("/dashboard").catch((err) => { });
      } else if (key == "2") {
        this.$router.push("/users").catch((err) => { });
      } else if (key == "3") {
        this.$router.push("/comments").catch((err) => { });
      }
    },
  },
  created() {
    this.$bus.$on("tt", (data) => {
      this.isCollapse = data;
    });
  },
};
</script>

<style scoped>
.iconfont {
  margin-left: 2px;
  margin-right: 10px;
}

.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 72px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}


.sidebar>ul {
  height: 100%;
}
</style>
