<template>
  <div>
    <vue-particles class="login-background" color="#97D0F2" :particleOpacity="0.8" :particlesNumber="60"
      shapeType="circle" :particleSize="4" linesColor="#97D0F2" :linesWidth="1" :lineLinked="true" :lineOpacity="0.4"
      :linesDistance="150" :moveSpeed="3" :hoverEffect="true" hoverMode="grab" :clickEffect="true" clickMode="push">
    </vue-particles>
    <div class="login-other">
        <el-form :rules="rulesL" ref="loginForm" :model="loginForm" class="loginContainer">
          <H3 class="loginTitle">后台管理系统</H3>
          <el-form-item prop="name">
            <el-input prefix-icon="el-icon-user-solid" size="normal" type="text" v-model="loginForm.account"
              placeholder="请输入登录名"></el-input>
          </el-form-item>
          <el-form-item prop="pwd">
            <el-input prefix-icon="el-icon-lock" size="normal" type="password" v-model="loginForm.password"
              placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-button size="normal" type="primary" style="width: 100%;margin-top: 15px;" @click="submitLogin">登
            录</el-button>
        </el-form>
    </div>


  </div>
</template>

<script>
import { loginTenant} from '@/api'
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        account: '',
        password: ''
      },
      disabled: false,
      //防止多次点击
      clickFlag:false,
      rulesL: {
        account: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      }
    }
  },
  methods: {
    submitLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (!this.clickFlag) {
            this.clickFlag=true;
            this.$message.loading('登录中...', 1, (() => {
            loginTenant(this.loginForm).then((res) => {
              this.clickFlag=false;
              var code = res.code;
              if (code != 0) {
                this.$message.warning(res.msg);
                return false;
              }
              this.$cookies.set("token", res.data);
              this.$message.success('登录成功');
              this.$router.push('/dashboard').catch(() => { });
            })
          }))
          }
          
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style scoped>
.login-background {
  /* background: linear-gradient(-180deg, #dcf2e6 0%, #ffffff 100%); */
  background-image: url(../assets/img/bj.jpg);
  width: 100%;
  height: 100%;
  /**宽高100%是为了图片铺满屏幕 */
  z-index: -1;
  position: absolute;
}

.login-other {
  z-index: 1;
  margin: 125px 0 0 calc(calc(100vw - 410px) / 2);
  position: absolute;
}

.loginContainer {
  border-radius: 10px;
  background-clip: padding-box;
  width: 380px;
  padding: 15px 35px 35px 35px;
  background: #fefefe;
  border: 1px solid #eaeaea;
}

.loginTitle {
  margin: 15px auto 20px auto;
  text-align: center;
  color: #000;
}

.loginRemember {
  text-align: left;
  margin: 0 0 15px 0;
}


.bind_code {
  position: relative;
}

.bind_code .el-input__inner {
  width: 328px;
  height: 44px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  outline: none;
  padding: 0 100px 0 10px;
}

.bind_code .code /deep/.el-input__suffix {
  right: 97px;
}

.bind_code .el-button {
  border: 0;
  background: none;
  padding: 0;
  border-radius: 0;
}

.bind_code.pic {
  width: 80px;
  height: 25px;
  border-left: none !important;
}

.bind_code .bind_code_gain {
  position: absolute;
  top: calc(50% - 9px);
  right: 10px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #20aee5;
  line-height: 18px;
  cursor: pointer;
  padding-left: 10px;
  border-left: 1px solid #d8d8d8;
}
</style>