<template>
    <div style="height: calc(100vh - 110px);
      overflow-y: auto;">
        <div class="container">
            <div class="handle-box">
                <el-select v-model="typeVal" placeholder="请选择搜索类型" style="padding: 0 15px;" @change="onSearchType">
                    <el-option v-for="item in selectContent" :key="item.field" :label="item.name" :value="item.field">
                    </el-option>
                </el-select>
                <template v-if="typeVal == 'status'">
                    <el-select v-model="statusVal" placeholder="选择状态" style="padding: 0 15px;width: 20%;"
                        @change="onChangStatus">
                        <el-option v-for="item in statusList" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </template>
                <template v-else>
                    <el-input placeholder="请输入搜索内容" v-model="content"
                        style="width: 20%;padding-right: 10px;"></el-input>
                    <el-button type="primary" @click="handleSearch" icon="el-icon-search" :disabled="disfle">搜索
                    </el-button>
                </template>

            </div>
            <el-table :data="tableData" border class="table" ref="filterTable" v-loading="loading">
                <el-table-column prop="status" label="评价状态" width="180" align="center">
                    <template slot-scope="scope">
                        <template v-if="scope.row.status == 1">
                            <el-tag type="info">待审核</el-tag>
                        </template>
                        <template v-if="scope.row.status == 2">
                            <el-tag type="success">已审核</el-tag>
                        </template>
                        <template v-if="scope.row.status == 3">
                            <el-tag type="danger">不通过</el-tag>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="pid" label="用户ID" width="120" align="center"></el-table-column>
                <el-table-column prop="pname" label="用户姓名" width="130"></el-table-column>
                <el-table-column prop="pphone" label="授权手机号" width="150"></el-table-column>
                <el-table-column prop="uid" label="被评用户ID" width="120" align="center"></el-table-column>
                <el-table-column prop="name" label="被评用户姓名" width="130"></el-table-column>
                <el-table-column prop="phone" label="被评用户手机号" width="150"></el-table-column>
                <el-table-column prop="content" label="评论内容" width="180" show-overflow-tooltip></el-table-column>
                <el-table-column prop="plTime" label="评价时间" width="150"></el-table-column>
                
                <el-table-column label="操作" width="220" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status==1" type="text" @click="handleAudit(scope.$index, scope.row)">
                            审核
                        </el-button>
                        <el-button type="text" style="color:#ff2000;" @click="handleDel(scope.$index, scope.row)">
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="page" :page-size="size"
                    layout="total, prev, pager, next" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { queryComments, querySearchComments, postAuditComments, deleteByComments } from '@/api';
export default {
    name: "CommentAdm",
    data() {
        return {
            imgUrl: '',
            loading: true,
            disfle: true,
            typeVal: '',
            statusVal: '',
            statusList: [{ name: '待审核', value: 1 }, { name: '审核通过', value: 2 }, { name: '审核失败', value: 3 }],
            selectContent: [
                {
                    name: '评价状态',
                    field: 'status'
                },
                {
                    name: '用户ID',
                    field: 'pid'
                },
                {
                    name: '用户姓名',
                    field: 'pname'
                },
                {
                    name: '授权手机',
                    field: 'pphone'
                },
                {
                    name: '被评用户ID',
                    field: 'uid'
                },
                {
                    name: '被评用户姓名',
                    field: 'name'
                },
                {
                    name: '被评授权手机',
                    field: 'phone'
                }
            ],
            //评论信息
            tableData: [],
            page: 1,
            size: 10,
            total: 0,
            //搜索
            content: ''
        };
    },
    watch: {
        content: {
            handler(val) {
                if (val) {
                    this.disfle = false
                } else {
                    this.disfle = true
                    this.getComments();
                }
            }
        }
    },
    methods: {
        async getSearch() {
            try {
                this.loading = true;
                let field = this.typeVal;
                let value = this.content;
                let pageNum = this.page;
                let pageSize = this.size;
                let index = this.selectContent.findIndex((item)=>item.field==field);
                if (field == 'pid') {
                    field = 'uid';
                }else if (field=='pname') {
                    field = 'name';
                }else if (field=='pphone') {
                    field = 'phone';
                }
                let ty = 0;
                if (field == 'status') {
                    ty = 3;
                } else {
                    ty = index > 3 ? 1 : 2
                }
                let res = await querySearchComments({ pageNum, pageSize, field, value, ty });
                this.tableData = res.list;
                this.total = res.total;
                setTimeout(() => {
                    this.loading = false;
                }, 500)
            } catch (error) {
                this.$message.error('服务器错误');
            }
        },
        onSearchType(field) {
            if (field != 'status') {
                this.statusVal='';
            }
            this.content = '';

        },
        //选择不同状态筛选
        onChangStatus(status) {
            this.content = status;
            this.page = 1;
            this.getSearch();
        },
        //审核
        handleAudit(index, row) {
            this.$confirm(`审核用户${row.pname}对${row.name}的评价:${row.content}`, "提示", {
                confirmButtonText: "通过",
                cancelButtonText: "拒绝",
                type: "warning",
            }).then(() => {
                postAuditComments({ id: row.id, status: 2 }).then((res) => {
                    if (res.code != 0) {
                        this.$message.error('通过失败');
                        return false;
                    }
                    this.$message.success('通过成功');
                    this.loading = true;
                    let index = this.tableData.map((item) => item.id.indexOf(row.id));
                    this.tableData[index].status = 2;
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            }).catch(() => {
                this.$prompt('请输入拒绝原因', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValidator: (value) => { //非空验证
                        if (!value) {
                            return '输入不能为空'
                        }
                    },
                }).then(({ value }) => {
                    postAuditComments({ id: row.id, status: 3, refMsg: value }).then((res) => {
                        if (res.code != 0) {
                            this.$message.error('拒绝失败');
                            return false;
                        }
                        this.$message.success('拒绝成功');
                        this.loading = true;
                        let index = this.tableData.map((item) => item.id.indexOf(row.id));
                        this.tableData[index].status = 3;
                        setTimeout(() => {
                            this.loading = false
                        }, 500)
                    })
                }).catch(() => {

                });

            });
        },
        handleDel(index, row) {
            this.$confirm("此操作将永久删除该评价, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                deleteByComments({id:row.id,uid:row.uid}).then((res) => {
                    if (res.code != 0) {
                        this.$message.error(res.msg);
                        return false;
                    }
                    this.$message.success(res.msg);
                    this.loading = true
                    this.tableData = this.tableData.filter((todo) => {
                        return todo.id != row.id;
                    })
                    setTimeout(() => {
                        this.loading = false
                    }, 500)
                })
            });
        },
        //搜索
        handleSearch() {
            if (this.typeVal == '') {
                this.$message.warning('请选择类型');
                return false;
            }
            this.page = 1;
            this.getSearch();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.getUserPage();
        },
        //获取评价信息列表
        async getComments() {
            try {
                this.loading = true;
                let pageNum = this.page;
                let pageSize = this.size;
                let res = await queryComments({ pageNum, pageSize });
                console.log(res);
                this.tableData = res.list;
                this.total = res.total;
                setTimeout(() => {
                    this.loading = false;
                }, 500)
            } catch (error) {
                this.$message.error('服务器错误');
            }
        },
    },
    created() {
        this.getComments();
    },
};
</script>

<style scoped>
.pp {
    margin-top: 10px;
    float: right;
    color: #909399;
}

.handle-box {
    margin-bottom: 20px;
}




.handle-select {
    width: 120px;
}

.handle-input {
    width: 300px;
}

.table {
    width: 100%;
    font-size: 14px;
}

.mr10 {
    margin-right: 10px;
}

.table-td-thumb {
    display: block;
    margin: auto;
    width: 40px;
    height: 40px;
}

.avatar-uploader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    border-radius: 6px;
    border: 1px #cdcdcd dashed;
    height: 150px;
    cursor: pointer;
}

.avatar-uploader-icon {
    font-size: 26px;

}

.avatar {
    width: 150px;
    height: 150px;
    border-radius: 6px;
}
</style>