//引入路由
import VueRouter from "vue-router";

import { getToken } from "@/utils/auth";

import Login from "@/views/Login";
import Home from "@/views/Home";
import Dashboard from "@/views/Dashboard";
import UsersAdm from "@/views/UsersAdm";
import CommentAdm from "@/views/CommentAdm";

const router = new VueRouter({
  routes: [
    { path: "/", redirect: "login" },
    {
      path: "/login",
      component: Login,
      meta: { is: true, title: "后台管理系统" },
    },
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "dashboard",
          component: Dashboard,
          meta: { title: "系统首页", isAust: true },
        },
        {
          path: "users",
          component: UsersAdm,
          meta: { title: "用户管理", isAust: true }
        },
        {
          path: "comments",
          component: CommentAdm,
          meta: { title: "评论管理", isAust: true }
        }
      ],
    },
  ],
});

//全局前置路由守卫----初始化的时候被调用,每次路由切换之前被调用  这个可以限制访问路由组件
router.beforeEach((to, form, next) => {
  if (to.meta.is) {
    if (getToken()) {
      next("/dashboard");
    } else {
      next();
    }
  } else {
    next();
  }

  if (to.meta.isAust) {
    if (getToken()) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

//全局后置路由守卫---初始化的时候被调用,每次路由切换之后被调用 这个可以用来切换网页的标题
router.afterEach((to, form) => {
  if (to.meta.title) {
    //切换网页标题
    document.title = to.meta.title;
  } else {
    document.title = "后台管理系统";
  }
});
export default router;
