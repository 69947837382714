import axios from "axios";

import { message } from "ant-design-vue";

import { getToken, removeToken } from "@/utils/auth";

import router from '@/router';

const service = axios.create({
  //http://127.0.0.1:9001/adm
  baseURL: "https://api.hzxd888.xyz/adm"
});

service.interceptors.request.use((config) => {
  //application/json
  //application/x-www-form-urlencoded
  let token = getToken();
  // //token存在设置到请求头上
  if (token) {
    config.headers['Token'] = token;
  }
  return config;
});

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code == 403) {
      message.warning("登录失效,请重新登录");
      removeToken();
      router.push('/login');
      sessionStorage.setItem("n", "1");
    }
    return res;
  },
  (error) => {
    let response=error.response;
    if (response) {
      let code = response.status
    if (code == 403) {
      removeToken();
      message.warning("无权限访问");
      router.push('/login');
      sessionStorage.setItem("n", "1");
    } else {
      message.error("服务器错误");
    }
    console.log(error);
    }else{
      message.error("服务器错误");
    }
    
    return Promise.reject(error);
  }
);

export default service;
